<template>
  <div class="login">
    <div
      @click="show"
      style="
        color: red;
        text-decoration: underline;
        cursor: pointer;
        width: fit-content;
        margin: auto;
      "
    >
      Admin connexion
    </div>
    <div v-if="admin" class="adminlogin">
      <div class="loginForm">
        <span class="adminX" @click="hide">X</span>
        <div class="loginHead">
          <img src="../assets/logo.png" width="50" alt="" />Victory-Partners-<span
            style="color: #bc1013"
            >Admin</span
          >
        </div>
        <div class="loginChild">
          <input type="text" placeholder="Login" v-model="login" />
        </div>
        <div class="loginChild">
          <input type="Password" placeholder="Password" v-model="password" />
        </div>
        <div class="loginChild">
          <input type="button" v-on:click="loginn" value="LOGIN" />
        </div>
        <div v-if="error" class="loginChild error">Login or Password incorrect!</div>
      </div>
    </div>
    <h1 id="title">Passez votre test de niveau en ligne Gratuit</h1>
    <h2
      class="eur"
      style="
        display: flex;
        align-items: center;
        width: 50%;
        margin: auto;
        color: blue;
        font-size: 20px;
      "
    >
      <img width="100" src="@/assets/eur.jpg" alt="" />
      <span style="text-align: center"
        >Tous nos programmes sont conformes au CECRL (Le Cadre européen commun de
        réference pour les langues)</span
      >
    </h2>
    <div class="logincnt">
      <div v-if="load" class="load">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div id="photo">
        <img id="laptop" width="700" src="../assets/laptop.jpg" alt="" />
      </div>
      <div id="form">
        <div id="snackbar"></div>
        <h2 style="text-align: center; margin-bottom: 20px">
          Remplir le formulaire suivant pour passer le test:
        </h2>
        <div class="input" id="name">
          <span>Nom et prénom<label>*</label> :</span
          ><input v-on:click="reColor('name')" v-model="nom" type="text" />
        </div>
        <div v-if="vNom" class="error">Nom et prénom Obligatoire!</div>
        <div class="input" id="date">
          <span>Date de naissance<label>*</label> :</span
          ><input
            v-on:click="reColor('date')"
            v-model="date"
            :max="
              new Date(new Date().setYear(new Date().getFullYear() - 5))
                .toISOString()
                .split('T')[0]
            "
            type="date"
          />
        </div>
        <div v-if="vDate" class="error">Date de naissance Obligatoire!</div>
        <div class="input" id="email">
          <span>Email :</span
          ><input
            placeholder="Entrer email valid!"
            v-on:click="reColor('email')"
            v-model="email"
            type="email"
          />
        </div>
        <div v-if="vEmail" class="error">Email invalide!</div>
        <div class="input" id="ville">
          <span>Ville actuelle<label>*</label> :</span>
          <span>
            <select name="" id="" v-model="vill" v-on:click="reColor('ville')">
              <option :value="null" disabled>
                choisir ville
              </option>
              <option v-for="v of villes" :key="v.id" :value="v.nom">{{v.nom}}</option>
            </select>
          </span>
        </div>
        <div v-if="vVille" class="error">
          Veuillez choisir ville!
        </div>
        <div class="input" v-if="vill=='autre'">
          <span>Entrer votre ville :</span
          ><input v-model="ville" type="text" id="ville" />
        </div>
        <div class="input" id="tel">
          <span>Téléphone <label>*</label> :</span
          ><input
            v-on:click="reColor('tel')"
            v-model="tel"
            type="text"
            id="phone"
            placeholder="ex: 0612345678"
          />
        </div>
        <div v-if="vTel" class="error">Téléphone format incorrect!</div>
        <div class="input" id="lang">
          <span>Intéréser par<label>*</label> :</span>
          <span id="intrs" style="display: inline-block">
            <span
              style="display: inline-block"
              v-for="m of matieres"
              :key="m.idMatiere"
            >
              {{ m.LibelleMatiere }}
              <span
                v-if="m.disponible == 0"
                style="display: inline-block; font-size: 12px; color: red"
                >(pas disponible)</span
              ><input
                v-on:click="reColor('lang')"
                name="lang"
                :disabled="m.disponible == 0 ? true : false"
                type="radio"
                :value="m.LibelleMatiere + '/' + m.idMatiere"
              />
            </span>
          </span>
        </div>
        <div v-if="vLang" class="error">Veuillez choisir au moins un choix!</div>
        <div class="input" id="pass">
          <span>Admin mot de passe<label>*</label> :</span
          ><input v-on:click="reColor('pass')" v-model="pass" type="password" />
        </div>
        <div v-if="vPass" class="error">Admin mot de passe Incorrect!</div>
        <div class="btnn"><button v-on:click="envoye">Envoyé</button></div>
      </div>
    </div>
  </div>
</template>

<script>
window.onbeforeunload = null;
import router from "@/router";
import { Rest } from "../providers/rest";
export default {
  name: "LoginAdmin",
  data() {
    return {
      login: "",
      password: "",
      error: false,
      nom: null,
      pass: "",
      vNom: false,
      vPass: false,
      date: null,
      vDate: false,
      email: "",
      vill: null,
      vEmail: false,
      vTel: false,
      vVille: false,
      tel: "",
      niveau: "",
      fr: false,
      en: false,
      vLang: false,
      load: false,
      ville: "",
      autre: false,
      admin: false,
      matieres: [],
      villes: [],
    };
  },
  mounted(){
    new Rest().getMatieres().then((rr) => {
      if (rr.data.success) {
        console.log(rr.data);
        this.matieres = rr.data.data;
      }
    });
    new Rest().getVilles().then((rrr) => {
      if (rrr.data.success) {
        console.log(rrr.data);
        this.villes = rrr.data.data;
      }
    });
  },
  methods: {
    show() {
      this.admin = true;
    },
    hide() {
      this.admin = false;
    },
    loginn: function () {
      this.error = false;
      new Rest()
        .login(this.login, this.password)
        .then((r) => {
          if (r.data.success) {
            if (r.data.login) {
              sessionStorage.setItem("token", this.login);
              router.push("/adminPanel");
            } else {
              this.error = true;
            }
          } else {
            alert(r.data.msg);
          }
        })
        .catch((e) => alert(e));
    },
    envoye: function () {
      this.load = true;
      let verify = true;
      new Rest()
        .login("localtest", this.pass)
        .then((r) => {
          if (r.data.success) {
            if (!r.data.login) {
              setTimeout(() => {
                  this.load = false;
                }, 500);
              verify = false;
              this.vPass = true;
              const a = document.getElementById("pass");
              if (a) {
                a.style.borderLeft = "5px solid red";
                a.children[1].style.border = "1px solid red";
              }
            }
            else{
              if (!this.nom) {
                verify = false;
                this.vNom = true;
                const a = document.getElementById("name");
                if (a) {
                  a.style.borderLeft = "5px solid red";
                  a.children[1].style.border = "1px solid red";
                }
              }
              if (!this.date) {
                verify = false;
                this.vDate = true;
                const a = document.getElementById("date");
                if (a) {
                  a.style.borderLeft = "5px solid red";
                  a.children[1].style.border = "1px solid red";
                }
              }
              if (this.vill == null) {
                verify = false;
                this.vVille = true;
                const a = document.getElementById("ville");
                if (a) {
                  a.style.borderLeft = "5px solid red";
                  a.children[1].children[0].style.border = "1px solid red";
                }
              }
              if (document.getElementsByName("lang").length == 0) {
                verify = false;
                this.vLang = true;
                const a = document.getElementById("lang");
                if (a) {
                  a.style.borderLeft = "5px solid red";
                  a.children[1].style.border = "1px solid red";
                }
              }
              let Vlang = true;
              for(const lang of document.getElementsByName("lang")){
                if(lang.checked == true){
                  Vlang = false;
                }
              }
              if (Vlang) {
                verify = false;
                this.vLang = true;
                const a = document.getElementById("lang");
                if (a) {
                  a.style.borderLeft = "5px solid red";
                  a.children[1].style.border =
                    "1px solid red";
                }
              }
              if (!this.tel) {
                verify = false;
                this.vTel = true;
                const a = document.getElementById("tel");
                if (a) {
                  a.style.borderLeft = "5px solid red";
                  a.children[1].style.border = "1px solid red";
                }
              }
              if (isNaN(this.tel) || (this.tel.length != 9 && this.tel.length != 10)) {
                verify = false;
                this.vTel = true;
                const a = document.getElementById("tel");
                if (a) {
                  a.style.borderLeft = "5px solid red";
                  a.children[1].style.border = "1px solid red";
                }
              }
              if (verify) {
                const rest = new Rest();
                const data = {
                  name: this.nom,
                  email: this.email,
                  date: this.date,
                  tel: this.tel,
                  niveau: "",
                  fr: false,
                  en: false,
                  local: "1",
                  ville: this.vill == "autre" ? this.ville : this.vill,
                };
                rest
                  .register(data)
                  .then((r) => {
                    if (r.data.success) {
                      let link = "";
                      const lang = document.getElementsByName("lang");
                      for (const l of lang) {
                        if (l.checked == true) {
                          const lang = l.value.split(
                            "/"
                          )[0];
                          const val = l.value.split("/")[1];
                          link =
                            "/main/" + val + "/" + r.data.id;
                        }
                      }
                      router.push(link);
                    } else {
                      this.snackBar("erreur 404!");
                      setTimeout(() => {
                        this.load = false;
                      }, 500);
                    }
                  })
                  .catch((e) => {
                    this.snackBar("erreur 404! Veuillez réessayer!!");
                    setTimeout(() => {
                      this.load = false;
                    }, 500);
                  });
              } else {
                setTimeout(() => {
                  this.load = false;
                }, 500);
              }
            }
          } else {
            alert(r.data.msg);
          }
        })
      .catch((e) => alert(e));
    },
    reColor: function (id) {
      const a = document.getElementById(id);
      if (a) {
        a.style.borderLeft = "none";
        a.children[1].style.border = "none";
        a.children[1].children[0].style.border = "none";
      }
      if (id == "name") this.vNom = false;

      if (id == "email") this.vEmail = false;

      if (id == "date") this.vDate = false;

      if (id == "lang") this.vLang = false;

      if (id == "tel") this.vTel = false;

      if (id == "pass") this.vPass = false;

      if (id == "ville") this.vVille = false;
    },
    snackBar: function (msg) {
      const x = document.getElementById("snackbar");
      if (x) {
        x.className = "show";
        x.innerText = msg;
        setTimeout(function () {
          if (x) {
            x.className = x.className.replace("show", "");
          }
        }, 3000);
      }
    },
  },
  beforeMount() {
    if (sessionStorage.getItem("token") != null) {
      router.push("/adminPanel");
    }
  },
};
</script>

<style>
.adminX {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  color: red;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}
.adminX:hover {
  background-color: red;
  color: white;
}
.adminlogin {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffa3;
  z-index: 2;
  display: flex;
  align-items: center;
}
.login #title {
    color: black;
    text-shadow: none;
  }
  .input [type="text"],
.input [type="email"],
.input [type="password"],
.input [type="date"] {
  box-shadow: 0 0 1px 0;
}
@media screen and (max-width: 1200px) {
  .logincnt {
    height: fit-content;
    display: block !important;
    text-align: center;
    padding-bottom: 50px;
  }
  #laptop {
    width: 100vw;
  }
  #title {
    font-size: 30px;
    margin: 20px;
  }
  #photo {
    margin: auto;
  }
  #form {
    margin: auto;
    margin-top: 20px;
    min-width: 0;
    width: 90%;
    padding: 30px 0;
    padding-bottom: 50px;
    position: relative;
  }
  .input {
    display: block;
    margin-top: 20px;
  }
  .input span {
    overflow-wrap: break-word;
    display: block;
    text-align: center;
  }
  h2 {
    width: 90% !important;
    font-size: 18px !important;
  }
  .eur {
    display: block !important;
    margin: 20px auto !important;
    text-align: center;
  }
  .eur span {
    display: block;
    font-size: 16px;
  }
  h1 {
    font-size: 20px !important;
  }
  .radio input {
    width: fit-content !important;
    margin: 5px;
  }
  .radio div {
    width: fit-content !important;
    display: inline;
  }
}
.login #nav {
  display: none;
}
.login .logincnt {
  margin-top: 40px;
  width: 100%;
  display: flex;
}
.login .loginForm {
  position: relative;
  width: fit-content;
  padding: 20px 30px;
  background-image: linear-gradient(
    to right,
    #a2c5dd,
    #8fb5d0,
    #7ca6c4,
    #6a97b7,
    #5788ab,
    #5788ab,
    #5788ab,
    #5788ab,
    #6a97b7,
    #7ca6c4,
    #8fb5d0,
    #a2c5dd
  );
  margin: auto;
}
.login .loginHead {
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}
.login .loginHead img {
  padding: 5px;
}
.login .loginChild {
  text-align: center;
}
.loginChild input {
  padding: 5px;
  width: 70%;
  margin: 10px;
}
.login .loginChild input[type="button"] {
  font-weight: bold;
  background-color: #bc1013;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}
.login .error {
  color: red;
}
::placeholder {
  text-align: center;
}
.login #form {
  padding: 30px 20px;
  background-color: #fafafc;
  border: 1px solid #e9e9eb;
  box-shadow: 0 0 20px 0 #d2d2d2 !important;
  margin: auto;
  box-shadow: 1px 1px 20px 0px;
  width: 30%;
  min-width: 400px;
  position: relative;
  padding-bottom: 55px;
  padding-bottom: 0;
}
.login #form .btnn {
  width: 100%;
  text-align: center;
}
.login #form .btnn button {
  padding: 10px 40px;
  background-color: red;
  border: none;
  color: white;
  border-radius: 20px;
  font-weight: bold;
  font-size: 19px;
  margin: 15px auto;
  cursor: pointer;
}
.login #form .btnn button:hover {
  background-color: #d84747;
}
</style>
